.Top {
  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: -1;
  opacity: 1;
  height: 190px;
  background-image : url('https://www.elitanken.se/images/TopBackground.PNG') ;
	background-size: cover; 
  font-size: 160px
}

.TopWrapper_ {
  z-index: 50;
  background-color: #23B8CC;
  opacity: 0.88;
  padding-top: 80px;
  height: 190px;

}

.TopLine {
  color: #fff;
  margin-bottom: 5px;
  font-family: Arial, Helvetica, sans-serif;  
  font-size: 50%;
}

@media all and  (max-width: 991px) {
  .Top {

    font-size: 80px
  }  
}