.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.QmeButton {
  color: #fff;
  border: 2px solid #23B8CC;
  background-color: #23B8CC !important;
  border-color: #23B8CC !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.CloudLoginButton {
  color: #fff;
  border: 2px solid #23B8CC;
  background-color: #23B8CC !important;
  border-color: #fff !important;

}

.CountDownTimer{

  
  font-size: xx-large;
}

.FormSpace {
  font-size: 22px;
  margin:0;
  padding:0;

}

.FormSpaceSpinner {
  font-size: 22px;
  min-height: 60px;
  margin-bottom: 14px;
  margin-top: 14px;
  padding-bottom: 10px;
  padding-top: 10px;

}

.FormHeaderText {
  font-size: 22px;


}


